import axios from 'axios';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        message: '',
      });
    
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
      
        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('mobile', formData.mobile);
        formDataToSend.append('message', formData.message);
      
        // Send data to the PHP backend
        const url = 'http://localhost/ydr-foundation/api/contact-us.php';
        axios.post(url, formDataToSend)
          .then(response => {
            console.log('Response from server:', response.data);
            toast.success('Message Sent Successfully!');
            // Reset form fields after successful submission
            setFormData({
              name: '',
              email: '',
              mobile: '',
              message: '',
            });
          })
          .catch(error => {
            console.error('Error submitting form data:', error);
          });
      };

  return (
    <>
        <div className="container-xxl bg-white p-0">
            <Helmet>
            <title>Contact us-Yamuna Digital Research Foundation</title>
            <meta 
                name="description"
                content="add contact content here"
            />
            </Helmet>
            {/* Page Header End */}
            <div className="container-xxl py-5 page-header position-relative mb-5">
                <div className="container py-5">
                <h1 className="display-2 text-white animated slideInDown mb-4">Contact Us</h1>
                <nav aria-label="breadcrumb animated slideInDown">
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                    <li className="breadcrumb-item"><Link to="#">Pages</Link></li>
                    <li className="breadcrumb-item text-white active" aria-current="page">Contact Us</li>
                    </ol>
                </nav>
                </div>
            </div>
            {/* Page Header End */}
            {/* Contact Start */}
            <div className="container-xxl py-5">
                <div className="container">
                <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 600}}>
                    <h1 className="mb-3">Get In Touch</h1>
                    <p>Get in touch, that helps us to promote the computer literay in rural area for underprivileged childre
                        and provide basic infrastructure.
                    </p>
                </div>
                <div className="row g-4 mb-5">
                    <div className="col-md-6 col-lg-4 text-center wow fadeInUp" data-wow-delay="0.1s">
                    <div className="bg-light rounded-circle d-inline-flex align-items-center justify-content-center mb-4" style={{width: 75, height: 75}}>
                        <i className="fa fa-map-marker-alt fa-2x text-primary" />
                    </div>
                    <h6>Sikandarapur, Ballia UP, India</h6>
                    </div>
                    <div className="col-md-6 col-lg-4 text-center wow fadeInUp" data-wow-delay="0.3s">
                    <div className="bg-light rounded-circle d-inline-flex align-items-center justify-content-center mb-4" style={{width: 75, height: 75}}>
                        <i className="fa fa-envelope-open fa-2x text-primary" />
                    </div>
                    <h6>ydrfoundation@gmail.com</h6>
                    </div>
                    <div className="col-md-6 col-lg-4 text-center wow fadeInUp" data-wow-delay="0.5s">
                    <div className="bg-light rounded-circle d-inline-flex align-items-center justify-content-center mb-4" style={{width: 75, height: 75}}>
                        <i className="fa fa-phone-alt fa-2x text-primary" />
                    </div>
                    <h6>+91-7024335529</h6>
                    </div>
                </div>
                <div className="bg-light rounded">
                    <div className="row g-0">
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                        <div className="h-100 d-flex flex-column justify-content-center p-5">
                            <h1 className="mb-3">Contact Us-</h1>                        
                            <form onSubmit={handleSubmit}>
                                <div className="row g-3">
                                <div className="col-sm-6">
                                    <div className="form-floating">
                                        <input type="text" className="form-control border-0" name="name" value={formData.name} onChange={handleInputChange} id="name" placeholder="Your Name" required />
                                        <label htmlFor="name">Your Name</label>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-floating">
                                        <input type="email" className="form-control border-0" name="email" value={formData.email} onChange={handleInputChange} id="email" placeholder="Your Email" required />
                                        <label htmlFor="email">Your Email</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <input type="text" className="form-control border-0" name="mobile" value={formData.mobile} onChange={handleInputChange} id="subject" placeholder="Subject" required />
                                        <label htmlFor="subject">Mobile No.</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <textarea className="form-control border-0" name="message" value={formData.message} onChange={handleInputChange} placeholder="Leave a message here" id="message" style={{height: 100}} defaultValue={""}  required />
                                        <label htmlFor="message">Message</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                                </div>
                                </div>
                            </form>
                            <ToastContainer />
                        </div>
                    </div>
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s" style={{minHeight: 400}}>
                        <div className="position-relative h-100">
                         <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14340.18547563282!2d83.78511023480904!3d26.032038815843805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1704863153313!5m2!1sen!2sin" width={600} height={450} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />

                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            {/* Contact End */}
            {/* Back to Top */}
            <a to="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up" /></a>
        </div>
    </>
  )
}

export default Contact
import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'

const Navbar = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/payment-button.js';
    script.setAttribute('data-payment_button_id', 'pl_NJcKKepQpcqO5d');
    script.async = true;

    // Append the script to the form element
    const form = document.getElementById('razorpayForm');
    if (form) {
      form.appendChild(script);
    }

    // Clean up the script on component unmount
    return () => {
      if (form) {
        form.removeChild(script);
      }
    };
  }, []);


  return (
    <>
      
      {/* Navbar Start */}
      <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top px-4 px-lg-3 py-lg-0">
        <NavLink to="/" className="navbar-brand">
          <h3 className="m-0 text-primary d-flex align-items-center">
            <img src="assets\img\logo.png" alt="" className="me-2" />
            <span className="d-lg-inline d-none">Yamuna Digital Research Foundation</span>
            <span className="d-inline d-lg-none">YDRF</span>
          </h3>
        </NavLink>
        <br /> <br />
        <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
        <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav mx-auto">
            <NavLink to="/" className="nav-item nav-link">Home</NavLink>
            <div className="nav-item dropdown">
            <NavLink to="/about-us" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">About</NavLink>
              <div className="dropdown-menu rounded-0 rounded-bottom border-0 shadow-sm m-0">
                <NavLink to="/about-us" className="dropdown-item">Know About Us</NavLink>
                <NavLink to="/our-volunteer" className="dropdown-item">Our Volunteer</NavLink>
                <NavLink to="/popular-teacher" className="dropdown-item">Know Our Donar</NavLink>
              </div>
            </div>
            <NavLink to="/contact-us" className="nav-item nav-link">Contact Us</NavLink>
            <NavLink to="/get-all-post" className="nav-item nav-link">Blog</NavLink>
            <div className="nav-item dropdown">
            <NavLink to="/school-facility" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Education</NavLink>
              <div className="dropdown-menu rounded-0 rounded-bottom border-0 shadow-sm m-0">
                <NavLink to="/syllabus" className="dropdown-item">Syllabus</NavLink>
                <NavLink to="/result" className="dropdown-item">Results</NavLink>
                <NavLink to="/become-trainer" className="dropdown-item">Apply for Trainer</NavLink>
              </div>
            </div>
            <div className="nav-item dropdown">
              <NavLink to="/school-facility" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Activity</NavLink>
                <div className="dropdown-menu rounded-0 rounded-bottom border-0 shadow-sm m-0">
                  <NavLink to="/quiz-competition" className="dropdown-item">Quiz Competion</NavLink>
                  <NavLink to="/festival-celebration" className="dropdown-item">Festival Celebration</NavLink>
                  <NavLink to="/appointment" className="dropdown-item">Make Appointment</NavLink>
                </div>
            </div>
          </div>
          {/* Razorpay payment button */}
          <form id="razorpayForm">
            {/* Button script will be appended here dynamically */}
          </form>
          {/* <NavLink to='' className="btn btn-primary rounded-pill px-3 d-none d-lg-block">Donate<i className="fa fa-arrow-right ms-3" /></NavLink> */}
        </div>
      </nav>
      {/* Navbar End */}
    </>
  )
}

export default Navbar;
import React from 'react'
import { Helmet } from 'react-helmet'

const About = () => {
  return (
    <>
        <div className="container-xxl bg-white p-0">
        <Helmet>
          <title>About us-Yamuna Digital Research Foundation</title>
          <meta 
            name="description"
            content="add about content"
          />
        </Helmet>
          {/* Page Header End */}
          <div className="container-xxl py-5 page-header position-relative mb-5">
            <div className="container py-5">
              <h1 className="display-2 text-white animated slideInDown mb-4">About Us</h1>
              <nav aria-label="breadcrumb animated slideInDown">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                  <li className="breadcrumb-item"><a href="#">Pages</a></li>
                  <li className="breadcrumb-item text-white active" aria-current="page">About Us</li>
                </ol>
              </nav>
            </div>
          </div>
          {/* Page Header End */}
          {/* About Start */}
          <div className="container-xxl py-5">
            <div className="container">
              <div className="row g-5 align-items-center">
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                  <h1 className="mb-4">Working on creating highly techie future workforce and promoting innovations, supporting Computer Science based research to contribute in Nation Building.</h1>
                  <p style={{color:'#fc2003'}}>"Education is the most Powerful Weapon which you can use to change the world." -Dr. APJ Abdul Kalam</p>
                  <p className="mb-4">Since 2023, our team is providing free basic computer education to underprivileged students.
                    We work as per Indian Government and industries guideline and our training courses designed as per NIELIT . Our trainers and volunteers are highly experienced and our Digital Lab is designed as per International Standards.
                    We are working in sync with our Government mission "Skill India" and "Digital India".
                    Your donations help us to set training labs on more technologies such as "Embedded System", "Internet of Things", "Data Science ", "Automation", "DevOps", "AI &ML" and Cloud Technologies.
                  </p>
                  <div className="row g-4 align-items-center">
                    <div className="col-sm-6">
                        <a className="btn btn-primary rounded-pill py-2 px-5" href>Our Mission</a><br /> <br />
                        <p>Our mission is to offer trainings and research opportunities to students on Computer Science Technologies.</p>
                    </div>
                    <div className="col-sm-6">
                      <a className="btn btn-success rounded-pill py-2 px-5" href>Our Vision</a> <br /><br />
                      <p>Build a valued Computer Research and Training Organization which contribute in our nation growth.</p>
                    </div>
                  </div>
                  <div className="col">
                        <a className="btn btn-warning py-2 px-5" href>MORE ABOUT US</a><br /> <br />
                  </div>
                </div>
                <div className="col-lg-6 about-img wow fadeInUp" data-wow-delay="0.5s">
                  <div className="row">
                    <div className="col-12 text-center">
                      <img className="img-fluid w-75 rounded-circle bg-light p-3" src="assets/img/about-1.jpg" alt />
                    </div>
                    <div className="col-6 text-start" style={{marginTop: '-150px'}}>
                      <img className="img-fluid w-100 rounded-circle bg-light p-3" src="assets/img/about-2.jpg" alt />
                    </div>
                    <div className="col-6 text-end" style={{marginTop: '-150px'}}>
                      <img className="img-fluid w-100 rounded-circle bg-light p-3" src="assets/img/about-3.jpg" alt />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* About End */}
          {/* Call To Action Start */}
          <div className="container-xxl py-5">
            <div className="container">
              <div className="bg-light rounded">
                <div className="row g-0">
                  <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s" style={{maxHeight: 300}}>
                    <div className="position-relative h-100">
                      <img className="position-absolute w-100 h-100 rounded" src="assets/img/call-to-action.jpg" style={{objectFit: 'cover'}} />
                    </div>
                  </div>
                  <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                    <div className="h-100 d-flex flex-column justify-content-center p-5">
                      <h3 className="mb-4">Why you should donate to Yamuna Digital Research Foundation ?</h3>
                      <p>We are committed to impacting our society with actions. We valued your donations and invest for the right 
                        requirements needed for the welfare of society. We keep all donations and expenses transparent and ensure it
                        must be rightly used.
                      </p>
                      <h4>* Opportunity to give back to Society</h4>
                      <p>Kindness is the one unique attribute that differentiates from others.By donating us you can give back to society.</p>
                      <h4>* Show Your Personal Values</h4>
                      <p>Being a donar you can show personal values towards society.</p>
                      <h4>* Tax Benefits</h4>
                      <p>You can avail Tax benefits under Income Tax Act Section 80G.</p>
                      <div className="col-sm-6">
                        <a className="btn btn-primary rounded-pill py-2 px-5" href>Donate US</a><br />
                        WANT TO KNOWMORE ABOUT US ?CALL NOW<p className="mb-2"><i className="fa fa-phone-alt me-3" />+91-7024335529</p>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Call To Action End */}
          {/* Team Start */}
          <div className="container-xxl py-5">
            <div className="container">
              <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 600}}>
                <h1 className="mb-3">Popular Teachers</h1>
                <p>Eirmod sed ipsum dolor sit rebum labore magna erat. Tempor ut dolore lorem kasd vero ipsum sit
                  eirmod sit. Ipsum diam justo sed rebum vero dolor duo.</p>
              </div>
              <div className="row g-4">
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                  <div className="team-item position-relative">
                    <img className="img-fluid rounded-circle w-75" src="assets/img/team-1.jpg" alt />
                    <div className="team-text">
                      <h3>Arbind Yadav</h3>
                      <p>Director</p>
                      <div className="d-flex align-items-center">
                        <a className="btn btn-square btn-primary mx-1" href><i className="fab fa-facebook-f" /></a>
                        <a className="btn btn-square btn-primary  mx-1" href><i className="fab fa-twitter" /></a>
                        <a className="btn btn-square btn-primary  mx-1" href><i className="fab fa-instagram" /></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                  <div className="team-item position-relative">
                    <img className="img-fluid rounded-circle w-75" src="assets/img/team-4.jpg" alt />
                    <div className="team-text">
                      <h3>Abhinash Kumar</h3>
                      <p>Software Trainer</p>
                      <div className="d-flex align-items-center">
                        <a className="btn btn-square btn-primary mx-1" href><i className="fab fa-facebook-f" /></a>
                        <a className="btn btn-square btn-primary  mx-1" href><i className="fab fa-twitter" /></a>
                        <a className="btn btn-square btn-primary  mx-1" href><i className="fab fa-instagram" /></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                  <div className="team-item position-relative">
                    <img className="img-fluid rounded-circle w-75" src="assets/img/team-5.jpg" alt />
                    <div className="team-text">
                      <h3>Priyanka</h3>
                      <p>Lab Trainer</p>
                      <div className="d-flex align-items-center">
                        <a className="btn btn-square btn-primary mx-1" href><i className="fab fa-facebook-f" /></a>
                        <a className="btn btn-square btn-primary  mx-1" href><i className="fab fa-twitter" /></a>
                        <a className="btn btn-square btn-primary  mx-1" href><i className="fab fa-instagram" /></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Team End */}
          {/* Back to Top */}
          <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up" /></a>
        </div>
    </>
  )
}

export default About
import React from 'react'
import { Helmet } from 'react-helmet';

const Blogs = () => {
  return (
    <>
        <div className="container-xxl bg-white p-0">
          <Helmet>
            <title>Blogs-Yamuna Digital Research Foundation</title>
            <meta 
              name="description"
              content="add Blogs content"
            />
          </Helmet>
          {/* Page Header End */}
          <div className="container-xxl py-5 page-header position-relative mb-5">
            <div className="container py-5">
              <h1 className="display-2 text-white animated slideInDown mb-4">Blogs</h1>
              <nav aria-label="breadcrumb animated slideInDown">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                  <li className="breadcrumb-item"><a href="#">Pages</a></li>
                  <li className="breadcrumb-item text-white active" aria-current="page">Blogs</li>
                </ol>
              </nav>
            </div>
          </div>
          {/* Page Header End */}
          {/* Classes Start */}
          <div className="container-xxl py-5">
            <div className="container">
              <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 600}}>
                <h1 className="mb-3">Blogs</h1>
                <p>Eirmod sed ipsum dolor sit rebum labore magna erat. Tempor ut dolore lorem kasd vero ipsum sit eirmod sit. Ipsum diam justo sed rebum vero dolor duo.</p>
              </div>
            </div>
          </div>
          {/* Classes End */}
          {/* Appointment Start */}
          <div className="container-xxl py-5">
            <div className="container">
              <div className="bg-light rounded">
                <div className="row g-0">
                  <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                    <div className="h-100 d-flex flex-column justify-content-center p-5">
                      <h1 className="mb-4">Post A Blog</h1>
                      <form>
                        <div className="row g-3">
                          <div className="col-sm-6">
                            <div className="form-floating">
                              <input type="text" className="form-control border-0" id="gname" placeholder="Gurdian Name" />
                              <label htmlFor="gname">Title</label>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-floating">
                              <input type="email" className="form-control border-0" id="gmail" placeholder="Gurdian Email" />
                              <label htmlFor="gmail">Paragraph</label>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-floating">
                              <textarea className="form-control border-0" placeholder="Leave a message here" id="message" style={{height: 100}} defaultValue={""} />
                              <label htmlFor="message">Message</label>
                            </div>
                          </div>
                          <div className="col-12">
                            <button className="btn btn-primary w-100 py-3" type="submit">POST</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s" style={{minHeight: 400}}>
                    <div className="position-relative h-100">
                      <img className="position-absolute w-100 h-100 rounded" src="assets/img/appointment.jpg" style={{objectFit: 'cover'}} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Appointment End */}
         
          {/* Back to Top */}
          <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up" /></a>
        </div>
    </>
  )
}

export default Blogs;
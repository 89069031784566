import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

const GetAllPost = () => {
    const [posts, setPosts] = useState([]);

    const fetchPosts = () => {
        const url = 'http://localhost/ydr-foundation/api/getAllPosts.php';
        axios.get(url)
            .then(response => {
                console.log('Posts from server:', response.data);
                setPosts(response.data.posts);
            })
            .catch(error => {
                console.error('Error fetching posts:', error);
            });
    };
    
    // Call fetchPosts when the component mounts
    useEffect(() => {
        fetchPosts();
    }, []);

    
      
  return (
    <>
        {/* Display fetched posts */}
        {/* <Link to='/blogpost' className='btn btn-primary'>Create A Post</Link> */}
            <div className="container mt-5">
                <h2>Recent Posts</h2>
                <div className="row">
                    {posts.map(post => (
                        <div key={post.id} className="col-md-4 mb-4">
                            <div className="card" style={{ height: '400px', width: '300px' }}>
                                <img
                                    src={'https://buffer.com/cdn-cgi/image/w=1000,fit=contain,q=90,f=auto/library/content/images/size/w1200/2023/10/free-images.jpg'}
                                    alt={post.title}
                                    className="card-img-top"
                                />
                                <div className="card-body">
                                    <h5 className="card-title">{post.title}</h5>
                                    <p className="card-text">{post.paragraph.slice(0, 20)}...</p>
                                    <Link to={`/blogpost/${post.id}`} className="btn btn-primary">Read More</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
    </>
  )
}
export default GetAllPost;
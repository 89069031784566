import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedRoutes=({Component})=>{
    const navigate = useNavigate()
    useEffect(()=>{
        const login = localStorage.getItem('email');
        if(!login){
            navigate('/')
        }
    })
    return(
        <>
            <Component />
        </>
    )
}
export default ProtectedRoutes;
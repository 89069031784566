import React, { useState } from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
Modal.setAppElement('#root');

const LoginModal = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });
  
  const isLogin = localStorage.getItem('email');
  

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    console.log('email', loginData.email);
    console.log('Password:',loginData.password);
    localStorage.setItem('email',loginData.email);
    localStorage.setItem('password',loginData.password);
    closeModal();

    

    try {
      const response = await fetch('http://localhost/ydr-foundation/api/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData),
      });

      const responseData = await response.json();

      if (response.ok) {
        if (responseData.status === 'success') {
          toast.success('Login successful!');

        } else {
          console.error('Login failed:', responseData.message);
        }
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const modalStyle = {
    content: {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      padding: '20px',
      backgroundColor: '#fff',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
      zIndex: '1',
    },
    overlay: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: '1',
    },
  };
  const buttonStyle = {
    backgroundColor: '#f77605',
    color: '#fff',
    padding: '10px 15px',
    marginRight:'5px',
    border: 'none',
    borderRadius: '5px',
    fontSize: '18px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    
  };

  const buttonHoverStyle = {
    backgroundColor: '#f77605',
  };

  const handleLogout=()=>{
    localStorage.clear();
    toast.success('Successfully logged out!', { position: toast.POSITION.TOP_RIGHT });
    window.location.reload();
  }
  return (
    <div>
    {
      isLogin ? (
        <>
        <button
        onClick={handleLogout}
        style={{ ...buttonStyle, ...buttonHoverStyle }}
      >
        Logout
      </button>
      <Link to='/create-post'><button
        
        style={{ ...buttonStyle, ...buttonHoverStyle }}
      >
        Post Blog
      </button></Link>
        </>
      ) : (
        <>
      <button
        onClick={openModal}
        style={{ ...buttonStyle, ...buttonHoverStyle }}
      >
        Admin Login
      </button>
        </>
      )
    }
      
      

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyle}
        contentLabel="Login Modal"
      >
        <span
          style={{
            cursor: 'pointer',
            float: 'right',
            fontSize: '20px',
            margin: '0 0 10px 10px',
          }}
          onClick={closeModal}
        >
          &times;
        </span>
        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Login</h2>
        <form onSubmit={handleLoginSubmit}>
          <input
            type="text"
            placeholder="Email"
            name="email" 
            value={loginData.email}
            onChange={handleLoginChange}
            style={inputStyle}
            required
          />
          <br />
          <input
            type="password"
            placeholder="Password"
            name="password" 
            value={loginData.password}
            onChange={handleLoginChange}
            style={inputStyle}
            required
          />
          <br />
          <button type="submit" style={buttonStyle}>
            Login
          </button>
        </form>
      </Modal>
      <ToastContainer />
    </div>
  );
};

const inputStyle = {
  width: '100%',
  padding: '10px',
  margin: '10px 0',
  boxSizing: 'border-box',
};

const buttonStyle = {
  backgroundColor: '#4caf50',
  color: '#fff',  
  padding: '10px 15px',
  border: 'none',
  borderRadius: '5px',
  fontSize: '16px',
  cursor: 'pointer',
};

export default LoginModal;

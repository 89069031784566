import React from 'react'
import { Link } from 'react-router-dom'
import LoginModal from './LoginModal'

const Footer = () => {
  return (
    <>
        {/* Footer Start */}
        <div className="container-fluid bg-dark text-white-50 footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                <div className="row g-5">
                    <div className="col-lg-3 col-md-6">
                    <h3 className="text-white mb-4">Get In Touch</h3>
                    <p className="mb-2"><i className="fa fa-map-marker-alt me-3" />Sikandarapur, Ballia UP, India</p>
                    <p className="mb-2"><i className="fa fa-phone-alt me-3" />+91-7024335529</p>
                    <p className="mb-2"><i className="fa fa-envelope me-3" />ydrfoundation@gmail.com</p>
                    <div className="d-flex pt-2">
                        <Link to="https://twitter.com/" className="btn btn-outline-light btn-social"><i className="fab fa-twitter" /></Link>
                        <Link to="https://www.facebook.com/" className="btn btn-outline-light btn-social"><i className="fab fa-facebook-f" /></Link>
                        <Link to="https://www.youtube.com/" className="btn btn-outline-light btn-social"><i className="fab fa-youtube" /></Link>
                        <Link to="https://www.linkedin.com/" className="btn btn-outline-light btn-social"><i className="fab fa-linkedin-in" /></Link>
                    </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                    <h3 className="text-white mb-4">Quick Links</h3>
                    <Link to="/about-us" className="btn btn-link text-white-50">About Us</Link>
                    <Link to="/contact-us" className="btn btn-link text-white-50">Contact Us</Link>
                    <Link to="" className="btn btn-link text-white-50">Donate US</Link>
                    <Link to="" className="btn btn-link text-white-50">Privacy Policy</Link>
                    <Link to="" className="btn btn-link text-white-50">Terms &amp; Condition</Link>
                    </div>
                    <div className="col-lg-3 col-md-6">
                    <h3 className="text-white mb-4">Photo Gallery</h3>
                    <div className="row g-2 pt-2">
                        <div className="col-4">
                        <img className="img-fluid rounded bg-light p-1" src="assets/img/classes-1.jpg" alt="mg" />
                        </div>
                        <div className="col-4">
                        <img className="img-fluid rounded bg-light p-1" src="assets/img/classes-2.jpg" alt="mg"/>
                        </div>
                        <div className="col-4">
                        <img className="img-fluid rounded bg-light p-1" src="assets/img/classes-3.jpg" alt="mg" />
                        </div>
                        <div className="col-4">
                        <img className="img-fluid rounded bg-light p-1" src="assets/img/classes-4.jpg" alt="mg" />
                        </div>
                        <div className="col-4">
                        <img className="img-fluid rounded bg-light p-1" src="assets/img/classes-5.jpg" alt="mg" />
                        </div>
                        <div className="col-4">
                        <img className="img-fluid rounded bg-light p-1" src="assets/img/classes-6.jpg" alt="mg" />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                    <h3 className="text-white mb-4">Newsletter</h3>
                    <p>Genius Quiz Competion about to be conducted at 20th January 2024</p>
                    <div className="position-relative mx-auto" style={{maxWidth: 400}}>
                        
                        <LoginModal />
                    </div>
                    </div>
                </div>
                </div>
                <div className="container">
                <div className="copyright">
                    <div className="row">
                    <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        © <a className="border-bottom" href="#">ydrfoundation.org</a>, All Right Reserved. 
                        {/*/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. *** /*/}
                        Designed By <Link className="border-bottom" to="https://htmlcodex.com">2023 ydrfoundation</Link>
                    </div>
                    <div className="col-md-6 text-center text-md-end">
                        <div className="footer-menu">
                        <Link to="">Home</Link>
                        <Link to="">Cookies</Link>
                        <Link to="">Help</Link>
                        <Link to="">FQAs</Link>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            {/* Footer End */}
    </>
  )
}

export default Footer
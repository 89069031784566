import React from 'react'
import { Helmet } from 'react-helmet';

const FestivalCelebration = () => {
  return (
    <>
        <div className="container-xxl bg-white p-0">
          <Helmet>
            <title>FestivalCelebration-Yamuna Digital Research Foundation</title>
            <meta 
              name="description"
              content="add FestivalCelebration content"
            />
          </Helmet>
          {/* Page Header End */}
          <div className="container-xxl py-5 page-header position-relative mb-5">
            <div className="container py-5">
              <h1 className="display-2 text-white animated slideInDown mb-4">Festival Celebration</h1>
              <nav aria-label="breadcrumb animated slideInDown">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                  <li className="breadcrumb-item"><a href="#">Pages</a></li>
                  <li className="breadcrumb-item text-white active" aria-current="page">Festival Celebration</li>
                </ol>
              </nav>
            </div>
          </div>
          {/* Page Header End */}
          {/* Classes Start */}
          <div className="container-xxl py-5">
            <div className="container">
              <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 600}}>
                <h1 className="mb-3">Festival Celebration</h1>
                <p>Eirmod sed ipsum dolor sit rebum labore magna erat. Tempor ut dolore lorem kasd vero ipsum sit eirmod sit. Ipsum diam justo sed rebum vero dolor duo.</p>
              </div>
            </div>
          </div>
          {/* Classes End */}
        
          {/* Back to Top */}
          <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up" /></a>
        </div>
    </>
  )
}

export default FestivalCelebration;
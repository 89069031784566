import Navbar from "./components/Header";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import PopularTeacher from "./pages/Donar";
import Appointment from "./pages/Appointment";
import Testimonial from "./pages/Testimonial";
import PageNotFound from "./pages/PageNotFound";
import Facility from "./pages/Volunteer";
import Footer from "./components/Footer";
import Syllabus from "./pages/Syllabus";
import Result from "./pages/Result";
import BecomeTrainer from "./pages/BecomeTrainer";
import {BrowserRouter,Routes,Route} from 'react-router-dom';
import FestivalCelebration from "./pages/FestivalCelebration";
import { Helmet } from "react-helmet";
import Blogs from "./pages/Blogs";
import QuizCompetition from "./pages/QuizCompetition";
import Dashboard from "./components/Dashboard";
import Register from "./components/Register";
import GetAllPost from "./components/GetAllPost";
import Login from "./components/Login";
import CreatePost from "./components/CreatePost";
import CreatePostModal from "./components/CreatePostModal";
import ProtectedRoutes from "./components/ProtectedRoutes";
import DetailedPage from "./components/DetailedPage";

function App() {
  return (
    <BrowserRouter>
    <Navbar />
    <Helmet>
      <title>Yamuna Digital Research Foundation</title>
      <meta 
        name="description"
        content="add home content"
       />
      <meta 
        name="keywords"
        content="home, about..."
       />
    </Helmet>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about-us' element={<About />} />
        <Route path='/blog' element={<Blogs />} />
        <Route path='/contact-us' element={<Contact />} />
        <Route path='/our-volunteer' element={<Facility />} />
        <Route path='/popular-teacher' element={<PopularTeacher />} />
        <Route path='/become-trainer' element={<BecomeTrainer />} />
        <Route path='/appointment' element={<Appointment />} />
        <Route path='/testimonial' element={<Testimonial />} />
        <Route path='/syllabus' element={<Syllabus />} />
        <Route path='/result' element={<Result />} />
        <Route path='/festival-celebration' element={<FestivalCelebration />} />
        <Route path='/quiz-competition' element={<QuizCompetition />} />
        <Route path='/register' element={< ProtectedRoutes Component={Register}  />} />
        <Route path='/login' element={<Login />} />
        <Route path='/get-all-post' element={<GetAllPost />} />
        <Route path='/get-all-post/:id' element={<DetailedPage />} />
        <Route path='/detail-page' element={<DetailedPage />} />
        <Route path='/create-post' element={<ProtectedRoutes Component={CreatePost} />} />
        <Route path='/create-post-modal' element={<CreatePostModal />} />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;

import React from 'react'
import '../App.css';

const Home = () => {
  return (
    <>
        <div className="container-xxl bg-white p-0">
            {/* Carousel Start */}
            <div className="container-fluid p-0 mb-5">
            <div id="imageSlider" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                <div className="carousel-item active">
                    <img
                    className="img-fluid"
                    src="assets/img/carousel-1.jpg"
                    alt=""
                    />
                    {/* Text overlay on the first slide */}
                    <div className="carousel-caption">
                    <h3>First Slide</h3>
                    <p>This is the caption for the first slide.</p>
                    <p>sdhbhfasgdivgasuidfvjasdvsaudgfuasbbhzjshdcu</p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img
                    height="50px"
                    className="img-fluid"
                    src="assets/img/carousel-2.jpg"
                    alt=""
                    />
                    {/* Text overlay on the second slide */}
                    <div className="carousel-caption">
                    <h3>Second Slide</h3>
                    <p>This is the caption for the second slide.</p>
                    </div>
                </div>
                </div>
                <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#imageSlider"
                data-bs-slide="prev"
                >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
                </button>
                <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#imageSlider"
                data-bs-slide="next"
                >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
                </button>
            </div>
            </div>
            {/* Carousel End */}
            {/* Be a Part of Change Start */}
            <div className="container-xxl py-5">
                <div className="container">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                    <h1 className="mb-4">Be part of a change you want to see in the world</h1>
                    <p style={{color:'#fc3903'}}>“Generosity consists not of the sum given, but the manner inwhich it is bestowed.”</p>
                    <p className="mb-4">How all this mistaken idea of denouncing pleasure and praising pain was born
                        and I will give you a complete account of the system expound the actually
                        teachings of the great explorer of the truth pursues.
                    </p>
                    <div className="row g-4 align-items-center">
                        <div className="col-sm-6">
                            <a className="btn btn-primary rounded-pill py-2 px-4" href>Our Mission</a><br /> <br />
                            <p>Beguiled and demoralized by the charms off pleasure the moments, so by desire trouble.</p>
                        </div>
                        
                        <div className="col-sm-6">
                        <a className="btn btn-success rounded-pill py-2 px-4" href>Our Vision</a> <br /><br />
                        <p>The great explorer of the truth, theats masters builders off human happiness no one rejects.</p>
                        </div>
                    </div>
                    <div className="col">
                            <a className="btn btn-warning py-2 px-5" href>MORE ABOUT US</a><br /> <br />
                        </div>
                    </div>
                    <div className="col-lg-6 about-img wow fadeInUp" data-wow-delay="0.5s">
                    <div className="row">
                        <div className="col-12 text-center">
                        <img className="img-fluid w-75 rounded-circle bg-light p-3" src="assets/img/about-1.jpg" alt="" />
                        </div>
                        <div className="col-6 text-start" style={{marginTop: '-150px'}}>
                        <img className="img-fluid w-100 rounded-circle bg-light p-3" src="assets/img/about-2.jpg" alt="" />
                        </div>
                        <div className="col-6 text-end" style={{marginTop: '-150px'}}>
                        <img className="img-fluid w-100 rounded-circle bg-light p-3" src="assets/img/about-3.jpg" alt="" />
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            {/* Be a Part of Change End */}
            {/* What we do Start */}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 600}}>
                        <h1 className="mb-3">What We Do</h1>
                        <p>We work in favour of people for their multi-dimensional growth and development by implementing different schemes and programmes like Jan Kalyan Yojna and Nishulk Shikshan Karyakaram for them.</p>
                    </div>
                    <div className="row g-4">
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="classes-item">
                                <div className="bg-light rounded-circle w-75 mx-auto p-3">
                                <img className="img-fluid rounded-circle" src="assets/img/classes-1.jpg" alt="" />
                                </div>
                                <div className="bg-light rounded p-4 pt-5 mt-n5">
                                    <a className="d-block text-center h3 mt-3 mb-4" href>Thankyou for Support</a>
                                    <p>Thankyou so much for providing Laptops, that will help underprivileged children to boost their digital literacy.</p>
                                    <div className="d-flex align-items-center justify-content-between mb-4">
                                        <div className="d-flex align-items-center">
                                            <img className="rounded-circle flex-shrink-0" src="assets/img/user.jpg" alt="" style={{width: 45, height: 45}} />
                                            <div className="ms-3">
                                                <h6 className="text-primary mb-1">Lucas Edward</h6>
                                                <small>Founder & CEO: Sun Life</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="classes-item">
                                <div className="bg-light rounded-circle w-75 mx-auto p-3">
                                <img className="img-fluid rounded-circle" src="assets/img/classes-2.jpg" alt="" />
                                </div>
                                <div className="bg-light rounded p-4 pt-5 mt-n5">
                                    <a className="d-block text-center h3 mt-3 mb-4" href>Thankyou for Support</a>
                                    <p>Thankyou so much for providing Laptops, that will help underprivileged children to boost their digital literacy.</p>
                                    <div className="d-flex align-items-center justify-content-between mb-4">
                                        <div className="d-flex align-items-center">
                                            <img className="rounded-circle flex-shrink-0" src="assets/img/user.jpg" alt="" style={{width: 45, height: 45}} />
                                            <div className="ms-3">
                                                <h6 className="text-primary mb-1">Lucas Edward</h6>
                                                <small>Founder & CEO: Sun Life</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="classes-item">
                                <div className="bg-light rounded-circle w-75 mx-auto p-3">
                                <img className="img-fluid rounded-circle" src="assets/img/classes-3.jpg" alt="" />
                                </div>
                                <div className="bg-light rounded p-4 pt-5 mt-n5">
                                    <a className="d-block text-center h3 mt-3 mb-4" href>Thankyou for Support</a>
                                    <p>Thankyou so much for providing Laptops, that will help underprivileged children to boost their digital literacy.</p>
                                    <div className="d-flex align-items-center justify-content-between mb-4">
                                        <div className="d-flex align-items-center">
                                            <img className="rounded-circle flex-shrink-0" src="assets/img/user.jpg" alt="" style={{width: 45, height: 45}} />
                                            <div className="ms-3">
                                                <h6 className="text-primary mb-1">Lucas Edward</h6>
                                                <small>Founder & CEO: Sun Life</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="classes-item">
                                <div className="bg-light rounded-circle w-75 mx-auto p-3">
                                <img className="img-fluid rounded-circle" src="assets/img/classes-4.jpg" alt="" />
                                </div>
                                <div className="bg-light rounded p-4 pt-5 mt-n5">
                                    <a className="d-block text-center h3 mt-3 mb-4" href>Thankyou for Support</a>
                                    <p>Thankyou so much for providing Laptops, that will help underprivileged children to boost their digital literacy.</p>
                                    <div className="d-flex align-items-center justify-content-between mb-4">
                                        <div className="d-flex align-items-center">
                                            <img className="rounded-circle flex-shrink-0" src="assets/img/user.jpg" alt="" style={{width: 45, height: 45}} />
                                            <div className="ms-3">
                                                <h6 className="text-primary mb-1">Lucas Edward</h6>
                                                <small>Founder & CEO: Sun Life</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="classes-item">
                                <div className="bg-light rounded-circle w-75 mx-auto p-3">
                                <img className="img-fluid rounded-circle" src="assets/img/classes-5.jpg" alt="" />
                                </div>
                                <div className="bg-light rounded p-4 pt-5 mt-n5">
                                    <a className="d-block text-center h3 mt-3 mb-4" href>Thankyou for Support</a>
                                    <p>Thankyou so much for providing Laptops, that will help underprivileged children to boost their digital literacy.</p>
                                    <div className="d-flex align-items-center justify-content-between mb-4">
                                        <div className="d-flex align-items-center">
                                            <img className="rounded-circle flex-shrink-0" src="assets/img/user.jpg" alt="" style={{width: 45, height: 45}} />
                                            <div className="ms-3">
                                                <h6 className="text-primary mb-1">Lucas Edward</h6>
                                                <small>Founder & CEO: Sun Life</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="classes-item">
                                <div className="bg-light rounded-circle w-75 mx-auto p-3">
                                <img className="img-fluid rounded-circle" src="assets/img/classes-6.jpg" alt="" />
                                </div>
                                <div className="bg-light rounded p-4 pt-5 mt-n5">
                                    <a className="d-block text-center h3 mt-3 mb-4" href>Thankyou for Support</a>
                                    <p>Thankyou so much for providing Laptops, that will help underprivileged children to boost their digital literacy.</p>
                                    <div className="d-flex align-items-center justify-content-between mb-4">
                                        <div className="d-flex align-items-center">
                                            <img className="rounded-circle flex-shrink-0" src="assets/img/user.jpg" alt="" style={{width: 45, height: 45}} />
                                            <div className="ms-3">
                                                <h6 className="text-primary mb-1">Lucas Edward</h6>
                                                <small>Founder & CEO: Sun Life</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
            {/* What we do End */}
            {/* Facilities Start */}
            <div className="container-xxl py-5">
                <div className="container">
                <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 600}}>
                    <h1 className="mb-3">Facilities</h1>
                    <p>Eirmod sed ipsum dolor sit rebum labore magna erat. Tempor ut dolore lorem kasd vero ipsum sit eirmod sit. Ipsum diam justo sed rebum vero dolor duo.</p>
                </div>
                <div className="row g-4">
                    <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="facility-item">
                        <div className="facility-icon bg-primary">
                        <span className="bg-primary" />
                        <i className="fa fa-bus-alt fa-3x text-primary" />
                        <span className="bg-primary" />
                        </div>
                        <div className="facility-text bg-primary">
                        <h3 className="text-primary mb-3">School Bus</h3>
                        <p className="mb-0">Eirmod sed ipsum dolor sit rebum magna erat lorem kasd vero ipsum sit</p>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="facility-item">
                        <div className="facility-icon bg-success">
                        <span className="bg-success" />
                        <i className="fa fa-futbol fa-3x text-success" />
                        <span className="bg-success" />
                        </div>
                        <div className="facility-text bg-success">
                        <h3 className="text-success mb-3">Playground</h3>
                        <p className="mb-0">Eirmod sed ipsum dolor sit rebum magna erat lorem kasd vero ipsum sit</p>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="facility-item">
                        <div className="facility-icon bg-warning">
                        <span className="bg-warning" />
                        <i className="fa fa-home fa-3x text-warning" />
                        <span className="bg-warning" />
                        </div>
                        <div className="facility-text bg-warning">
                        <h3 className="text-warning mb-3">Healthy Canteen</h3>
                        <p className="mb-0">Eirmod sed ipsum dolor sit rebum magna erat lorem kasd vero ipsum sit</p>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div className="facility-item">
                        <div className="facility-icon bg-info">
                        <span className="bg-info" />
                        <i className="fa fa-chalkboard-teacher fa-3x text-info" />
                        <span className="bg-info" />
                        </div>
                        <div className="facility-text bg-info">
                        <h3 className="text-info mb-3">Positive Learning</h3>
                        <p className="mb-0">Eirmod sed ipsum dolor sit rebum magna erat lorem kasd vero ipsum sit</p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            {/* Facilities End */}
            
            {/* Call To Action Start */}
            <div className="container-xxl py-5">
                <div className="container">
                <div className="bg-light rounded">
                    <div className="row g-0">
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s" style={{minHeight: 400}}>
                        <div className="position-relative h-100">
                        <img className="position-absolute w-100 h-100 rounded" src="assets/img/call-to-action.jpg" style={{objectFit: 'cover'}} />
                        </div>
                    </div>
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                        <div className="h-100 d-flex flex-column justify-content-center p-5">
                        <h1 className="mb-4">Become A Student</h1>
                        <p className="mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos.
                            Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo magna dolore erat amet
                        </p>
                        <a className="btn btn-primary py-3 px-5" href>Get Started Now<i className="fa fa-arrow-right ms-2" /></a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            {/* Call To Action End */}
            {/* Classes Start */}
            
            {/* Become a Volunteer Start */}
            <div className="container-xxl py-5">
                <div className="container">
                <div className="bg-light rounded">
                    <div className="row g-0">
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                        <div className="h-100 d-flex flex-column justify-content-center p-5">
                        <h1 className="mb-4">Become a Volunteer</h1>
                        <form>
                            <div className="row g-3">
                            <div className="col-sm-6">
                                <div className="form-floating">
                                <input type="text" className="form-control border-0" id="gname" placeholder="Gurdian Name" />
                                <label htmlFor="gname">Enter Name</label>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-floating">
                                <input type="email" className="form-control border-0" id="gmail" placeholder="Gurdian Email" />
                                <label htmlFor="gmail">Enter Email</label>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-floating">
                                <input type="text" className="form-control border-0" id="cname" placeholder="Child Name" />
                                <label htmlFor="cname">Enter Subject</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-floating">
                                <textarea className="form-control border-0" placeholder="Leave a message here" id="message" style={{height: 100}} defaultValue={""} />
                                <label htmlFor="message">Message</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <button className="btn btn-primary w-100 py-3" type="button">Submit</button>
                            </div>
                            </div>
                        </form>
                        </div>
                    </div>
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s" style={{minHeight: 400}}>
                        <div className="position-relative h-100">
                        <img className="position-absolute w-100 h-100 rounded" src="assets/img/appointment.jpg" style={{objectFit: 'cover'}} />
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            {/* Become a Volunteer End */}
            {/* Team Start */}
            <div className="container-xxl py-5">
                <div className="container">
                <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 600}}>
                    <h1 className="mb-3">Team behind YDR Foundation</h1>
                    <p>Our work would not be possible without the work of our dedicated volunteers.</p>
                </div>
                <div className="row g-4">
                    <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="team-item position-relative">
                            <img className="img-fluid rounded-circle w-75" src="assets/img/team-1.jpg" alt="" />
                            <div className="team-text">
                                <h3>Arbind Yadav</h3>
                                <p>Director</p>
                                <div className="d-flex align-items-center">
                                    <a className="btn btn-square btn-primary mx-1" href><i className="fab fa-facebook-f" /></a>
                                    <a className="btn btn-square btn-primary  mx-1" href><i className="fab fa-twitter" /></a>
                                    <a className="btn btn-square btn-primary  mx-1" href><i className="fab fa-instagram" /></a>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="team-item position-relative">
                                <img className="img-fluid rounded-circle w-75" src="assets/img/team-2.jpg" alt="" />
                                <div className="team-text">
                                    <h3>Ajit Yadav</h3>
                                    <p>Volunteer</p>
                                    <div className="d-flex align-items-center">
                                        <a className="btn btn-square btn-primary mx-1" href><i className="fab fa-facebook-f" /></a>
                                        <a className="btn btn-square btn-primary  mx-1" href><i className="fab fa-twitter" /></a>
                                        <a className="btn btn-square btn-primary  mx-1" href><i className="fab fa-instagram" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="team-item position-relative">
                                <img className="img-fluid rounded-circle w-75" src="assets/img/team-3.jpg" alt="" />
                                <div className="team-text">
                                    <h3>Anshika Yadav</h3>
                                    <p>Volunteer</p>
                                    <div className="d-flex align-items-center">
                                        <a className="btn btn-square btn-primary mx-1" href><i className="fab fa-facebook-f" /></a>
                                        <a className="btn btn-square btn-primary  mx-1" href><i className="fab fa-twitter" /></a>
                                        <a className="btn btn-square btn-primary  mx-1" href><i className="fab fa-instagram" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                            <div className="team-item position-relative">
                                <img className="img-fluid rounded-circle w-75" src="assets/img/team-4.jpg" alt="" />
                                <div className="team-text">
                                    <h3>Abhinash Kumar</h3>
                                    <p>Software Trainer</p>
                                    <div className="d-flex align-items-center">
                                        <a className="btn btn-square btn-primary mx-1" href><i className="fab fa-facebook-f" /></a>
                                        <a className="btn btn-square btn-primary  mx-1" href><i className="fab fa-twitter" /></a>
                                        <a className="btn btn-square btn-primary  mx-1" href><i className="fab fa-instagram" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.8s">
                            <div className="team-item position-relative">
                                <img className="img-fluid rounded-circle w-75" src="assets/img/team-5.jpg" alt="" />
                                <div className="team-text">
                                    <h3>Priyanka</h3>
                                    <p>Lab Trainer</p>
                                    <div className="d-flex align-items-center">
                                        <a className="btn btn-square btn-primary mx-1" href><i className="fab fa-facebook-f" /></a>
                                        <a className="btn btn-square btn-primary  mx-1" href><i className="fab fa-twitter" /></a>
                                        <a className="btn btn-square btn-primary  mx-1" href><i className="fab fa-instagram" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.9s">
                            <div className="team-item position-relative">
                                <img className="img-fluid rounded-circle w-75" src="assets/img/team-6.jpg" alt="" />
                                <div className="team-text">
                                    <h3>Devanand Verma</h3>
                                    <p>Volunteer</p>
                                    <div className="d-flex align-items-center">
                                        <a className="btn btn-square btn-primary mx-1" href><i className="fab fa-facebook-f" /></a>
                                        <a className="btn btn-square btn-primary  mx-1" href><i className="fab fa-twitter" /></a>
                                        <a className="btn btn-square btn-primary  mx-1" href><i className="fab fa-instagram" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Team End */}
            
            {/* Back to Top */}
            <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up" /></a>
            </div>

    </>
  )
}

export default Home;
import axios from 'axios';
import React, { useState } from 'react';

const CreatePost = () => {
    const [formData, setFormData] = useState({
        title: '',
        heading: '',
        paragraph: '',
        phone: '',
      });
     
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
      
        const formDataToSend = new FormData();
        formDataToSend.append('title', formData.title);
        formDataToSend.append('heading', formData.heading);
        formDataToSend.append('paragraph', formData.paragraph);
        formDataToSend.append('phone', formData.phone);
      
        // Send data to the PHP backend
        const url = 'http://localhost/ydr-foundation/api/postBlog.php';
        axios.post(url, formDataToSend)
          .then(response => {
            console.log('Response from server:', response.data);
            // Reset form fields after successful submission
            setFormData({
              title: '',
              heading: '',
              paragraph: '',
              phone: '',
            });
            alert("Posted Successfully!")

          })
          .catch(error => {
            console.error('Error submitting form data:', error);
          });
      };
  return (
    <>
        <div className="container-xxl bg-white p-0">
            {/* Contact Start */}
            <div className="container-xxl py-5">
                <div className="container">
                <div className="bg-light rounded">
                    <div className="row g-0">
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                        <div className="h-100 d-flex flex-column justify-content-center p-5">
                            <h1 className="mb-3">Post A Blog-</h1>                        
                            <form onSubmit={handleSubmit}>
                                <div className="row g-3">
                                <div className="col-sm-6">
                                    <div className="form-floating">
                                        <label htmlFor="title">Title</label>
                                        <input type="text" className="form-control border-0" name="title" value={formData.title} onChange={handleInputChange} id="title" placeholder="Enter Title" required />

                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-floating">
                                        <label htmlFor="heading">Heading</label>
                                        <input type="text" className="form-control border-0" name="heading" value={formData.heading} onChange={handleInputChange} id="heading" placeholder="Enter Heading" required />

                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <label htmlFor="phone">phone</label>
                                        <input type="text" className="form-control border-0" name="phone" value={formData.phone} onChange={handleInputChange} id="phone" placeholder="Enter phone" required />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <label htmlFor="paragraph">Paragraph</label>
                                        <textarea className="form-control border-0" name="paragraph" value={formData.paragraph} onChange={handleInputChange} placeholder="Enter Paragraph" id="paragraph" style={{height: 100}} defaultValue={""}  required />

                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-primary w-100 py-3" type="submit">Create Post</button>
                                </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            {/* Contact End */}

        </div>
    </>
  )
}

export default CreatePost;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate()
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://localhost/ydr-foundation/api/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData),
      });

      const responseData = await response.json();

      if (response.ok) {
        if (responseData.status === 'success') {
          alert('Login successful!');
          navigate('/create-post')

        } else {
          console.error('Login failed:', responseData.message);
        }
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  return (
    <div className="bg-light rounded d-flex flex-column justify-content-center align-item-center">
                    <div className="row g-0">
                    <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                        <div className="h-100 d-flex flex-column justify-content-center p-5">
                            <h1 className="mb-3"> Admin Login</h1>                        
                            <form onSubmit={handleLoginSubmit}>
                                <div className="row g-3">
                                <div className="col-12">
                                    <div className="form-floating">
                                        <input type="email" className="form-control border-0" name="email" value={loginData.email} onChange={handleLoginChange} id="email" placeholder="Enter Email" required />
                                        <label htmlFor="name">Email</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <input type="password" className="form-control border-0" name="password" value={loginData.password} onChange={handleLoginChange} id="password" placeholder="Enter Password" required />
                                        <label htmlFor="password">Password</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                                </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    </div>
                </div>
  );
};

export default Login;











import React, { useState } from 'react';
import axios from 'axios';

const Register = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('http://localhost/ydr-foundation/api/register.php', formData);
            console.log(response.data);
            alert("User Registered Successfully!")
            // Handle success or redirect to login page
        } catch (error) {
            console.error('Registration failed', error);
            // Handle error, display message, etc.
        }
    };

    return (
        <form onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: 'auto' }}>
            <label style={{ display: 'block', marginBottom: '8px' }}>
                Full Name:
                <input type="text" name="name" onChange={handleChange} style={inputStyle} />
            </label>
            <br />
            <label style={{ display: 'block', marginBottom: '8px' }}>
                Email:
                <input type="email" name="email" onChange={handleChange} style={inputStyle} />
            </label>
            <br />
            <label style={{ display: 'block', marginBottom: '8px' }}>
                Password:
                <input type="password" name="password" onChange={handleChange} style={inputStyle} />
            </label>
            <br />
            <button type="submit" style={buttonStyle}>Register</button>
        </form>
    );
};

// Styles
const inputStyle = {
    width: '100%',
    padding: '8px',
    boxSizing: 'border-box',
    marginBottom: '8px',
};

const buttonStyle = {
    backgroundColor: '#4CAF50',
    color: 'white',
    padding: '10px 15px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
};

export default Register;

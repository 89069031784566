import React from 'react'
import { Helmet } from 'react-helmet';

const BecomeTrainer = () => {
  return (
    <>
      <div className="container-xxl bg-white p-0">
        <Helmet>
          <title>BecomeTrainer-Yamuna Digital Research Foundation</title>
          <meta 
            name="description"
            content="add BecomeTrainer content"
          />
        </Helmet>
        {/* Page Header End */}
        <div className="container-xxl py-5 page-header position-relative mb-5">
          <div className="container py-5">
            <h1 className="display-2 text-white animated slideInDown mb-4">Become A Teachers</h1>
            <nav aria-label="breadcrumb animated slideInDown">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="#">Home</a></li>
                <li className="breadcrumb-item"><a href="#">Pages</a></li>
                <li className="breadcrumb-item text-white active" aria-current="page">Become A Teachers</li>
              </ol>
            </nav>
          </div>
        </div>
        {/* Page Header End */}
        {/* Call To Action Start */}
        <div className="container-xxl py-5">
          <div className="container">
            <div className="bg-light rounded">
              <div className="row g-0">
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s" style={{minHeight: 400}}>
                  <div className="position-relative h-100">
                    <img className="position-absolute w-100 h-100 rounded" src="assets/img/call-to-action.jpg" style={{objectFit: 'cover'}} />
                  </div>
                </div>
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                  <div className="h-100 d-flex flex-column justify-content-center p-5">
                    <h1 className="mb-4">Become A Teacher</h1>
                    <p className="mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos.
                      Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo magna dolore erat amet
                    </p>
                    <a className="btn btn-primary py-3 px-5" href>Get Started Now<i className="fa fa-arrow-right ms-2" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Call To Action End */}
        {/* Back to Top */}
        <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up" /></a>
      </div>
    </>
  )
}

export default BecomeTrainer;